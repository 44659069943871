import classNames from 'classnames';
import { cloneElement } from 'react';
import { useDiveContext } from '../../context';
import { DiveIconPosition } from '../../types/Dive.types';
import { stickerCVA, stickerIconCVA, stickerLabelCVA } from './Sticker.cva';
import { StickerProps } from './Sticker.types';

/**
 * A Sticker is to be displayed over an existing visual context (e.g.: `Media`).
 *
 * It shows small amounts of advisory information relevant to the context it hovers.
 *
 * @example
 * // Sticker used in a MediaOverlay component:
 * <MediaOverlay
 *   sticker={<Sticker icon={<SvgPlay />} variant="offered-content" label="This media is offered" />}
 * />
 */
export function Sticker({
  label,
  icon,
  variant = 'date',
  iconPosition = DiveIconPosition.Left,
  className,
  'data-testid': dataTestId,
}: StickerProps): JSX.Element {
  const { isTv } = useDiveContext();

  return (
    <span
      role="status"
      data-testid={dataTestId}
      className={classNames(stickerCVA({ variant, isTv, iconPosition }), className)}
    >
      {icon && (
        <span className={classNames('flex items-center')} aria-hidden>
          {cloneElement(icon, { className: stickerIconCVA({ variant, isTv }), 'aria-hidden': true })}
        </span>
      )}
      <p className={classNames(stickerLabelCVA({ variant, isTv, iconPosition, hasIcon: !!icon }))}>{label}</p>
    </span>
  );
}
