import { isOngoing } from '../../helpers/card/card-helper';
import { ProgressBarProps } from '../ProgressBar/ProgressBar';
import { CardProgressBarProps } from './types';

export const getProgressBarProps = (props: CardProgressBarProps): ProgressBarProps | undefined => {
  const { onGoing, userProgress, isCompleted } = props;
  const { startTime, endTime } = onGoing || {};

  if (isOngoing(onGoing) && !userProgress && !isCompleted) {
    return {
      startTime,
      endTime,
    };
  }

  if (isCompleted || userProgress) {
    return {
      userProgress,
      isCompleted,
      isOnGoing: true,
    };
  }

  return undefined;
};
