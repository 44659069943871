import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './Card.css';
import { getProgressBarProps } from './helpers';
import { CardProgressBarProps } from './types';

function CardProgressBar(props: CardProgressBarProps): JSX.Element | null {
  const progressBarProps = getProgressBarProps(props);

  return progressBarProps ? (
    <div className={styles.card__progressBarPositioner}>
      <ProgressBar {...progressBarProps} />
    </div>
  ) : null;
}

export default CardProgressBar;
