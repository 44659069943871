import { PersoLists } from '@canalplus/sdk-hodor';
import React from 'react';
import IconClose from '../../../assets/svg/close.svg';
import I18n from '../../../lang';
import styles from './CardRemoveButton.css';

export type CardRemoveButtonProps = {
  contentID: string;
  listType?: PersoLists;
  onClickRemove?: (e: React.MouseEvent, contentID: string, listType: PersoLists) => void;
};

/** Basic CardRemoveButton component used to remove card of your personal list */
export function CardRemoveButton({ contentID, listType, onClickRemove }: CardRemoveButtonProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const title = listType === PersoLists.Recommendations ? t('Card.notInterested') : t('Card.removeFromList');

  const handleClick = (e: React.MouseEvent) => {
    if (listType) {
      onClickRemove?.(e, contentID, listType);
    }
  };

  return (
    <button
      data-testid="cardRemoveButton"
      className={styles.cardRemoveButton}
      onClick={handleClick}
      type="button"
      title={title}
    >
      <IconClose title={title} />
    </button>
  );
}
