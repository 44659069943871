import classNames from 'classnames';
import styles from './Label.css';

export type LabelColor = 'raspberry' | 'black' | 'tvod' | 'blue' | 'red' | 'grey';

export type LabelProps = {
  children: string;
  color?: LabelColor;
  e2e?: string;
  isRemovableItem?: boolean;
  rounded?: boolean;
  size?: 'extra-small' | 'small' | 'medium' | 'large';
};

function Label({
  children,
  color = 'raspberry',
  e2e = 'label-card',
  isRemovableItem = false,
  rounded = false,
  size = 'small',
}: LabelProps): JSX.Element {
  return (
    <div
      className={classNames(
        styles.label,
        styles[`label--${color}`],
        styles[`label--${size}`],
        rounded && styles['label--rounded'],
        {
          [styles['label--isRemovableItem']]: isRemovableItem,
        }
      )}
      data-e2e={e2e}
    >
      {children}
    </div>
  );
}

export default Label;
