import { KEYCODE, Ratio } from '@canalplus/mycanal-commons';
import { CreativeMediaCard } from '@canalplus/mycanal-creativemedia';
import '@canalplus/mycanal-creativemedia/dist/css/CreativeMediaCard/CreativeMediaCard.css';
import { ContentDescription, Card as GenericCard } from '@canalplus/mycanal-sharedcomponent';
import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { REFRESH_PROGRESS_BAR_MS } from '../../constants/limits';
import { StrateMode } from '../../constants/strates';
import { getCardLogoChannelSize, isOngoing } from '../../helpers/card/card-helper';
import I18n from '../../lang';
import AvailabilityLabel from '../CardContents/AvailabilityLabel/AvailabilityLabel';
import CardLogoChannel from '../CardContents/CardLogoChannel/CardLogoChannel';
import { CardRemoveButton } from '../CardContents/CardRemoveButton/CardRemoveButton';
import Channel from '../Channel/Channel';
import stylesChannel from '../Channel/Channel.css'; // eslint-disable-line
import Label from '../Label/Label';
import Sticker from '../Sticker/Sticker';
import styles from './Card.css';
import CardProgressBar from './CardProgressBar';
import PlayBackIcon from './PlayBackIcon/PlayBackIcon';
import { CardProps } from './types';

/**
 * Component used to render a Poster, Title, Subtitle and LogoChannel
 *
 * @param title                      title of content to display
 * @param subtitle                   subtitle of content to display
 * @param description                description of content to display
 * @param image                      object with default URL (required) and mobile URL (optional)
 * @param logoChannel                url of logoChannel image
 * @param ratio                      ratio used for image sizes
 * @param onClick                    function to trigger when clicking on card
 * @param onGoing                    infos about the progress of the media
 * @param action                     CTA or action to pass to card
 * @param isRemovableItem            If the item can be removed
 * @param contentID                  (For CardRemoveBtn) ContentID of the item to delete
 * @param listType                   (For CardRemoveBtn) listType of the Card
 * @param lastDays                   if the last days of availability is defined
 * @param availabilityEndDate        Availability end date timestamp if defined
 * @param userProgress               progression of the user viewing movies
 * @param isCompleted                if finished or not to watch
 * @param isArticle                  whether the content is a creative media article
 * @param isFocused                  Tells whether this element is currently focused on TV
 * @param isLogoTyped                whether the content has a title incrusted - logoTyped
 * @param e2e                        end to end selector
 * @param cardType                   card type depending row type (standard - liveTV - etc...)
 * @param altImage                   alternative text to image
 * @param altLogoChannel             alternative text to logo image
 * @param isLiveIconEnabled          whether the live icon is enabled or not
 * @param isLifecycleLabelEnabled    whether a lifecycle label is enabled or not
 * @param imageQualityPercentage     image quality in percentage
 * @param dimensions                 image size for multi devices
 */
function Card({
  action,
  altImage = '',
  altLogoChannel = '',
  ariaLabel = '',
  availabilityEndDate,
  availabilityEndDateLabel,
  borderRadius = false,
  cardType = StrateMode.Standard,
  contentID = '',
  description = '',
  disabledStyle = { disabled: false, opacityOnPoster: false },
  displayName = '',
  duration = '',
  e2e = '',
  forceDisplayTitle = false,
  image = { default: '' },
  imageSize = 'normal',
  isBackgroundTransparent = false,
  imageSpecificities,
  initPlayer,
  isAlreadyReadable = true,
  isArticle = false,
  isCompleted,
  isFocused = false,
  isInOffer = true,
  isLifecycleLabelEnabled = true,
  isLiveGridCard = false,
  isLiveIconEnabled = true,
  isLiveOnGoingSubtype = false,
  isPlayBackIconShown = false,
  isPurchasableOrRentable = false,
  isRemovableItem = false,
  isTvDevice = false,
  isTVoD = false,
  isLogoTyped = false,
  lastDays = false,
  listType,
  logoChannel = { default: '' },
  maxImageRatio,
  metainfosInside = false,
  minimumPriceInfo,
  onClick,
  onClickRemove,
  onGoing,
  ratio = Ratio.Ratio169,
  size = 0,
  subtitle = '',
  svg,
  title = '',
  titleDisplayMode = TitleDisplayMode.All,
  userProgress,
  dimensions,
  imageQualityPercentage,
  startTime,
  sticker,
  dataTvFocusable,
}: CardProps): JSX.Element {
  const [isLive, setIsLive] = useState(isOngoing(onGoing));
  const [userUsedTab, setUserUsedTab] = useState(false);

  const { t } = I18n.useTranslation();

  const isLiveTvCard = cardType === StrateMode.LiveTv;
  const isLiveCard = isLiveTvCard || isLiveGridCard || isLiveOnGoingSubtype;
  const isLogoTypedModeAndTitleNotIncrusted = titleDisplayMode === TitleDisplayMode.LogoTyped && !isLogoTyped;
  const hasHoverTile = isLiveTvCard || isLogoTypedModeAndTitleNotIncrusted;

  const handleFirstTab = (event: KeyboardEvent) => {
    if (event.which === KEYCODE.TAB) {
      setUserUsedTab(true);
      window.removeEventListener('keydown', handleFirstTab);
      window.addEventListener('mousedown', handleMouseDownOnce);
    }
  };

  const handleMouseDownOnce = () => {
    setUserUsedTab(false);
    window.addEventListener('keydown', handleFirstTab);
  };

  useEffect(() => {
    if (!isTvDevice) {
      window.addEventListener('keydown', handleFirstTab);

      return () => {
        window.removeEventListener('keydown', handleFirstTab);
      };
    }

    return;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLiveCard) {
      const timeInterval = setInterval(() => {
        setIsLive(isOngoing(onGoing));
      }, REFRESH_PROGRESS_BAR_MS);

      return () => {
        clearInterval(timeInterval);
      };
    }
    return undefined;
  }, [onGoing, isLiveCard]);

  const getCardContents = () => {
    const customTitleOverCardTheme = {
      cardSubtitle: styles.cardSubtitle,
      cardTitle: styles.cardTitleDark,
      ...(hasHoverTile && {
        textStyle: styles.textOnImg,
      }),
    };

    const cardContents: JSX.Element[] = [];

    // If the card has an image specifities equal to "isLogo" this means
    // it is not a content, therefore every other card content is irrelevant
    if (imageSpecificities === 'isLogo') {
      return (
        <Channel
          altImage={altImage}
          dimensions={dimensions}
          logoUrl={image}
          classname={classNames(stylesChannel.cardLogo)}
        />
      );
    }

    // Showing a button allowing to remove a content when it is contained in a personnal strate (like playlist)
    if (isRemovableItem && !isTvDevice) {
      cardContents.push(
        <CardRemoveButton key="removebutton" contentID={contentID} listType={listType} onClickRemove={onClickRemove} />
      );
    }

    const shouldShowLiveLabel =
      isLiveIconEnabled &&
      !userProgress &&
      !isCompleted &&
      (isLiveTvCard || isLiveOnGoingSubtype || (isLive && isInOffer)) &&
      isTvDevice;

    const shouldShowSticker = !!(
      !isTVoD &&
      isLifecycleLabelEnabled &&
      !userProgress &&
      !isCompleted &&
      (lastDays || shouldShowLiveLabel || onGoing)
    );

    if (sticker || shouldShowSticker) {
      cardContents.push(
        <Sticker
          key="sticker"
          sticker={sticker}
          className={classNames(styles.card__sticker, {
            [styles.card__sticker__isRemovableItem]: isRemovableItem,
          })}
          variant={(lastDays && 'last-days') || (shouldShowLiveLabel && 'live') || undefined}
          startTime={onGoing?.startTime}
          endTime={onGoing?.endTime}
        />
      );
    }

    // Showing information about the content availability in a
    // TVOD context (minimum price to buy it or when your rental expire)
    if (availabilityEndDateLabel || availabilityEndDate || minimumPriceInfo) {
      cardContents.push(
        <div className={styles.card__availabilityLabel} data-e2e="card__availabilityLabel" key="availabilityLabel">
          <AvailabilityLabel
            isAlreadyReadable={isAlreadyReadable}
            availabilityEndDate={availabilityEndDate}
            availabilityEndDateLabel={availabilityEndDateLabel}
            minimumPriceInfo={minimumPriceInfo}
          />
        </div>
      );
    }

    const isLabelWithSeasonShown = isTVoD && !isPurchasableOrRentable && !isAlreadyReadable;

    if (isLabelWithSeasonShown) {
      cardContents.push(
        <div className={`${styles['card__labelWrapper--transparent']}`} key="withSeason">
          <Label color="black" e2e="card__label--withSeason" rounded>
            {t('Card.withSeason')}
          </Label>
        </div>
      );
    }

    const logoChannelSize = getCardLogoChannelSize({ imageSize, isLiveGridCard, isLiveTvCard });
    const shouldAddShadow = ['large', 'extraLarge'].includes(logoChannelSize);
    if (logoChannel?.default || hasHoverTile) {
      cardContents.push(
        <CardLogoChannel
          key="logochannel"
          addShadow={shouldAddShadow}
          altImage={altLogoChannel}
          borderRadius={borderRadius}
          disabled={disabledStyle?.disabled}
          isGradientActive={hasHoverTile}
          isLiveTvCard={isLiveCard}
          isProgressBarDisplayed={isOngoing(onGoing) || !!userProgress}
          logo={logoChannel}
          logoChannelSize={logoChannelSize}
        >
          {hasHoverTile && (
            <>
              <div
                className={classNames(
                  styles.card__titlesHoverCard__title,
                  styles[`card__titlesHoverCard__title--${imageSize}-${ratio}`]
                )}
                data-e2e="card__titlesHoverCard__title"
              >
                <ContentDescription
                  title={title}
                  subtitle={isLiveCard ? subtitle : ''}
                  startTime={0}
                  theme={customTitleOverCardTheme}
                />
              </div>
              {action && <div className={styles.card__action}>{typeof action === 'function' ? action() : action}</div>}
            </>
          )}
        </CardLogoChannel>
      );
    }

    // Add Live icon for LiveContentRow or if content if currently live in any strate type (except Live TV or program in progress)
    if (
      isLiveIconEnabled &&
      !userProgress &&
      !isCompleted &&
      (isLiveTvCard || isLiveOnGoingSubtype || (isLive && isInOffer)) &&
      !isTvDevice
    ) {
      cardContents.push(
        <Sticker
          key="liveicon"
          variant="live"
          className={classNames(styles.card__sticker, {
            [styles.card__sticker__isRemovableItem]: isRemovableItem,
          })}
        />
      );
    }

    if (isPlayBackIconShown && !isLabelWithSeasonShown && !isPurchasableOrRentable) {
      cardContents.push(<PlayBackIcon key="playbackicon" />);
    }

    return cardContents;
  };

  const customTheme = {
    cardTitleFocused: isFocused && styles.cardTitleFocused,
    cardSubtitle: styles.cardSubtitle,
    cardSubtitleFocused: isFocused && styles.cardSubtitleFocused,
    cardDescription: styles.cardDescription,
    cardDescriptionFocused: isFocused && styles.cardDescriptionFocused,
    cardContent: styles.cardContent,
  };

  if (isArticle) {
    return (
      <CreativeMediaCard
        dimensions={dimensions}
        ratio={ratio}
        title={title}
        subtitle={subtitle}
        image={image}
        theme={customTheme}
      >
        {logoChannel?.default ? (
          <CardLogoChannel key="logochannel" logo={logoChannel} altImage={altLogoChannel} />
        ) : null}
      </CreativeMediaCard>
    );
  }

  const ProgressBar = <CardProgressBar onGoing={onGoing} isCompleted={isCompleted} userProgress={userProgress} />;

  const isBottomTitlesDisabled =
    (([TitleDisplayMode.LogoTyped, TitleDisplayMode.None] as string[]).includes(titleDisplayMode) || isLiveTvCard) &&
    !forceDisplayTitle;

  return (
    <GenericCard
      action={action}
      altImage={altImage}
      ariaLabel={ariaLabel}
      borderRadius={borderRadius}
      description={description}
      dimensions={dimensions}
      disabledStyle={disabledStyle}
      disableTitles={isBottomTitlesDisabled}
      displayName={displayName}
      duration={duration}
      e2e={e2e}
      image={image}
      imageQualityPercentage={imageQualityPercentage}
      imageType={imageSpecificities}
      isLiveGridCard={isLiveGridCard}
      isTvDevice={isTvDevice}
      loading="lazy"
      maxImageRatio={maxImageRatio}
      metainfosInside={metainfosInside}
      onClick={initPlayer ? (e: any) => initPlayer(e, contentID, 'primary') : onClick}
      onKeyDown={(event?: React.KeyboardEvent) => (event?.which === KEYCODE.ENTER && onClick ? onClick() : () => {})}
      progressBar={ProgressBar}
      ratio={ratio}
      size={size}
      startTime={startTime}
      subtitle={subtitle}
      svg={svg}
      theme={customTheme}
      title={title}
      userUsedTab={userUsedTab}
      dataTvFocusable={dataTvFocusable}
      isBackgroundTransparent={isBackgroundTransparent}
    >
      {getCardContents()}
    </GenericCard>
  );
}

export default Card;
