import { DeviceToImageUrl } from '@canalplus/dive';
import { ImageType, ImageTypeDimensions } from '@canalplus/mycanal-sharedcomponent';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { imageQualityPercentageSelector } from '../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { imageQualityPercentageBySettingsSelector } from '../../store/slices/user-selectors';
import styles from './Channel.css';

export type IChannelProps = {
  altImage: string;
  dimensions: ImageTypeDimensions;
  logoUrl: DeviceToImageUrl;
  classname?: string;
  isSearch?: boolean;
};

/**
 * Channel
 *
 * Used to display a channel picture
 *
 * @param logoUrl     object with default URL (required) and mobile URL (optional)
 * @param altImage    alternative text to image
 * @param isSearch    is visible on Search Grid
 * @param dimensions  image size for multi device
 * @param classname   Add a custom **classname**
 */
function Channel({ isSearch = false, logoUrl, altImage, dimensions, classname }: IChannelProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const imageQualityPercentageBySettings = useSelector(imageQualityPercentageBySettingsSelector);
  const imageQualityPercentage = useSelector(imageQualityPercentageSelector);
  const imageQuality = imageQualityPercentageBySettings || imageQualityPercentage;

  return (
    <div className={classNames(styles.channel, classname)}>
      <ImageType
        className={classNames(styles.channel__logo, {
          [styles['channel--isSearch']]: isSearch,
        })}
        URLImage={logoUrl}
        altImage={altImage}
        dimensions={dimensions}
        imageQualityPercentage={imageQuality}
        isTvDevice={isTvDevice}
      />
    </div>
  );
}

export default Channel;
